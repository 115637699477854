import {Component, OnInit} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
@Component({
    selector: 'app-library',
    templateUrl: './library.component.html',
    styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit{

    selectedWebsiteName: string;


    ngOnInit() {
        this.selectedWebsiteName = localStorage.getItem('selectedWebsiteName');
    }
}
