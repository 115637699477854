<section class="content">
    <div class="container-fluid">
        <div class="row mt-2 mb-2" *ngIf="this.selectedWebsiteName == 'nsbedcollegetheni.org'">
            <div class="col-12">
                <h6 class="mt-3 text-primary text-bold">Welcome to NADAR SARASWATHI COLLEGE OF EDUCATION</h6>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-lg-6 col-md-6 col-12 mt-md-2 mb-3">
                <h5 class="m-0 ml-3 mt-md-2 title-album">News Feed <span class="h4" *ngIf="isAdmin == 'ALL'"><img src="assets/img/icons/plus.png"
                                                                                     class="img-fluid" type="button"
                                                                                     (click)="openModal();"></span></h5>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <div class="form-floating float-lg-right">
                    <input type="search" class="form-control" placeholder="search" [(ngModel)]="searchText">
                    <label class="text-gray">Search</label>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="card direct-chat direct-chat-primary card-light-blue"
                 *ngFor="let newsFeed of this.allNewsFeedList | filter: searchText; let i= index;">
                <div class="card-header ui-sortable-handle border-0" type="button" style="cursor: move;" (click)="toggleCard(i)">
                    <h3 class="card-title mt-2">{{newsFeed.title}}</h3>
                    <div class="btn-group ml-2">
                        <button type="button" class="btn btn-md" *ngIf="isAdmin == 'ALL' ">
                            <img src="assets/img/icons/pencil.png" class="img-fluid" title="Click to edit news feed"
                                 (click)="openEditModal(newsFeed, i)">
                        </button>
                        <button type="button" class="btn btn-md text-gradient-blue" *ngIf="isAdmin == 'ALL'">
                            <img src="assets/img/icons/bin.png" class="img-fluid" title="Click to delete news feed" (click)="deleteBlogDetails(newsFeed.newsFeedId, i)">
                        </button>
                    </div>
                    <div class="card-tools">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" [checked]="newsFeed.status === 'Showing'" (change)="openStatus(newsFeed.newsFeedId, newsFeed.status, $event,i)">
                        </div>
                        <!--<button type="button" class="btn text-gradient-blue">
                            <i class="fas"
                               [ngClass]="{'fa-minus': !isCardCollapsed[i], 'fa-plus': isCardCollapsed[i]}"></i>
                        </button>-->
                    </div>
                </div>

                <div class="card-body" [style.display]="isCardCollapsed[i] ? 'none' : 'block'">
                    <div class="direct-chat-messages">
                        <div class="container-fluid col-lg-11">
                            <div class="row">
                                <div class="col-lg-9 col-md-7 col-12">
                                    <h6 class="mb-3">Start Date : <span class="font-weight-light">{{newsFeed.startDate | date: 'dd-MM-yyyy'}}</span></h6><span><h6 class="mb-3" *ngIf="newsFeed.endDate != ''">End Date : <span class="font-weight-light">{{newsFeed.endDate | date: 'dd-MM-yyyy'}}</span></h6></span>
                                    <h6 class="mb-3">Venue : <span class="font-weight-light text-capitalize">{{newsFeed.city}}</span></h6>
                                    <h6 class="">Description : </h6>
                                    <span class="text-capitalize mt-3" [innerHTML]="newsFeed.description"></span>
                                </div>
                                <div class="col-lg-3 col-md-5 col-12">
                                    <div class="card">
                                        <img src="https://tomcat3.varnik.cloud:8443/varnik-cms-ws/api/front-end/news_feed/image/{{newsFeed.newsFeedId}}?website_name={{newsFeed.websiteName}}"
                                             class="img-fluid" (error)="handleImageError();" loading="lazy">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--News Feed Modal-->
        <div class="modal" style="background-color: rgba(0,0,0,0.4)" id="exampleModal" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalLabel" aria-hidden="true" [style.display]="isModalVisible ? 'block' : 'none'">
            <div class="modal-dialog modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">News Feed details</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                (click)="closeModal();">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="news">
                            <div class="row mb-3">
                                <div class="col-lg-12">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="title"
                                               placeholder="Enter News Feed Title">
                                        <label class="fw-bolder text-gray">News Feed Title</label>
                                    </div>
                                </div>
                                <span class="text-danger"
                                      *ngIf="newsErrors.title">{{newsErrors.title}}</span>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-12">
                                    <!--<div class="form-floating">
                                        <textarea class="form-control" placeholder="Description" id="floatingTextarea"
                                                  formControlName="description"></textarea>
                                        <label for="floatingTextarea" class="fw-bolder text-gray">Description</label>
                                    </div>-->

                                    <div class="form-control quill-height">
                                        <label>Description <span
                                                class="text-danger">*</span></label>
                                        <div class="" style="min-height: 150px; overflow: auto;">
                                            <quill-editor class="quill-editor" formControlName="description"
                                                          (onContentChanged)="onContentChanged($event)" [modules]="editorModules">
                                            </quill-editor>
                                        </div>
                                    </div>
                                </div>
                                <span class="text-danger"
                                      *ngIf="newsErrors.description">{{newsErrors.description}}</span>
                            </div>
                            <div class="row mb-3 mt-3">
                                <div class="col-lg-12">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="city"
                                               placeholder="Enter City Name">
                                        <label class="fw-bolder text-gray">Venue</label>
                                    </div>
                                </div>
                                <span class="text-danger"
                                      *ngIf="newsErrors.city">{{newsErrors.city}}</span>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-12">
                                    <div class="form-floating">
                                        <input type="date" class="form-control" formControlName="startDate" id="Datetime">
                                        <label class="fw-bolder text-gray">Start Date</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-12">
                                    <div class="form-floating">
                                        <input type="date" class="form-control" formControlName="endDate">
                                        <label class="fw-bolder text-gray">End Date</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-12">
                                    <div class="form-floating">
                                        <input class="form-control" type="file" (change)="onFileSelected($event)">
                                        <label class="form-label">Image</label>
                                        <span class="text-danger file-size">
                            <b>Image Requirements:</b>
                            <b class="ml-3">File Size:</b> Below 3 MB,
                            <b class="ml-3">Accepted Formats:</b> JPG, JPEG, PNG
                        </span>
                                    </div>

                                    <div class="form-floating mt-2" *ngIf="onlyEditShow == true">
                                        <input type="text" class="form-control" [value]="news.get('imageName').value" readonly>
                                        <label class="fw-bolder text-gray">Image Name</label>
                                    </div>

                                    <div class="row mt-3 bg-light">
                                        <div class="col-7 p-3 m-3">
                                            <label>Image Status Show / Hide</label>
                                        </div>
                                        <div class="col-2 p-3 m-3">
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" role="switch" [checked]="imageShowStatus == 'SHOWING' " (change)="updateImageShowStatus($event)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" (click)="onSubmit();"
                                class="btn btn-primary">{{this.updateButtonShow == false ? 'Add News Feed Details' : 'Update News Feed Details'}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>