

<section class="content">
    <div class="container-fluid">
        <div class="row mt-2 mb-2" *ngIf="this.selectedWebsiteName == 'nsbedcollegetheni.org'">
            <div class="col-12">
                <h6 class="mt-3 text-primary text-bold">Welcome to NADAR SARASWATHI COLLEGE OF EDUCATION</h6>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-lg-4 col-md-4 col-12 mt-md-2 mb-3">
                <h5 class="m-0 ml-3 mt-md-2 title-album">Albums <span class="h4" *ngIf="isAdmin == 'ALL'"><img src="assets/img/icons/plus.png"
                                                                          class="img-fluid" type="button"
                                                                          (click)="openModal();"></span></h5>
            </div>
            <div class="col-lg-4 col-md-4 col-12 mt-md-2 mb-3">
            </div>
            <div class="col-lg-4 col-md-6 col-12">
                <div class="form-floating float-lg-right">
                    <input type="search" class="form-control" placeholder="search"  [(ngModel)]="searchText">
                    <label class="text-gray">Search</label>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="card direct-chat direct-chat-primary card-light-blue" *ngFor="let album of this.getAllAlbumsList | filter: searchText; let i= index;" [ngClass]="{'maximized-card': isCardMaximized[i]}">
                <div class="card-header ui-sortable-handle border-0" type="button" style="cursor: move;"  (click)="toggleCard(i)">
                    <h3 class="card-title mt-2">{{album.albumName}}</h3>
                    <div class="btn-group ml-2">
                        <button type="button" class="btn btn-md" *ngIf="isAdmin == 'ALL' ">
                           <!-- <i class="fas fa-edit" type="button" title="Click to edit album name" (click)="openEditModal(album.albumName, album.albumDetailsSeq, i);"></i>-->
                            <img src="assets/img/icons/pencil.png" class="img-fluid" title="Click to edit album name" (click)="openEditModal(album.albumName, album.albumDetailsSeq, i);">
                        </button>
                        <button type="button" class="btn btn-md text-gradient-blue" *ngIf="isAdmin == 'ALL'">
                           <!-- <i class="far fa-trash-alt" title="Click to delete album" (click)="deleteAlbumDetails(album.albumDetailsSeq, i)"></i>-->
                            <img src="assets/img/icons/bin.png" class="img-fluid" title="Click to delete album" (click)="deleteAlbumDetails(album.albumDetailsSeq, i)">
                        </button>
                    </div>
                    <div class="card-tools">
                        <span class="badge badge-primary" title="{{album.pictureDetailsList.length}} Images">{{album.pictureDetailsList.length}} Images</span>
                       <!-- <button type="button" class="btn text-gradient-blue" (click)="toggleCardMaximize(i)">
                            <i class="fas" [ngClass]="{'fa-expand': !isCardMaximized[i], 'fa-compress': isCardMaximized[i]}"></i>
                        </button>-->
                        <!--<button type="button" class="btn text-gradient-blue"  (click)="toggleCard(i)">
                            <i class="fas" [ngClass]="{'fa-minus': !isCardCollapsed[i], 'fa-plus': isCardCollapsed[i]}"></i>
                        </button>-->
                    </div>
                </div>

                <div class="card-body" [style.display]="isCardCollapsed[i] ? 'none' : 'block'">
                    <div class="direct-chat-messages">
                   <!--<div class="container-fluid col-lg-12">
                       <div class="row mt-3">
                           &lt;!&ndash;<img src="http://136.233.82.212:8080/varnik-cms-ws/api/admin-dashboard/gallery/getImage/{{pic.pictureDetailsSeq}}">&ndash;&gt;
                               <div class="card" style="height: 100px; width: 100px;" *ngFor="let pic of album.pictureDetailsList; let j= index">
                                   <img src="http://136.233.82.212:8080/varnik-cms-ws/api/front-end/gallery/getImage/{{pic.pictureDetailsSeq}}" (error)="handleImageError();">
                                   <div class="minus-icon float-right" (click)="deleteImageAlbumImages(pic.pictureDetailsSeq, j, i)">
                                       <i class="fas fa-minus-circle"></i>
                                   </div>
                               </div>
                       </div>
                   </div>-->

                        <div class="container-fluid col-lg-11">
                            <swiper  [config]="configCity" [navigation]="true"  [scrollbar]="{ draggable: true }">
                                <ng-template swiperSlide *ngFor="let pic of album.pictureDetailsList; let j= index">
                                    <div class="card" style="height: 150px;width: 150px;">
                                        <img src="https://tomcat3.varnik.cloud:8443/varnik-cms-ws/api/front-end/gallery/getImage/{{pic.pictureDetailsSeq}}" class="img-fluid" loading="lazy">
                                        <div class="minus-icon float-right" (click)="deleteImageAlbumImages(pic.pictureDetailsSeq, j, i)" title="click to delete image" *ngIf="isAdmin == 'ALL' ">
                                           <!-- <i class="fas fa-minus-circle"></i>-->
                                            <img src="assets/img/icons/minus.png" class="img-fluid">
                                        </div>
                                    </div>
                                </ng-template>
                            </swiper>
                        </div>
                    </div>
                </div>
                <div class="card-footer bg-transparent border-0" [style.display]="isCardCollapsed[i] ? 'none' : 'block'">
                    <div class="card-tools float-right" *ngIf="isAdmin == 'ALL' || isAdmin == 'WRITE'">
                        <div class="form-floating">
                            <input class="form-control" type="file" id="formFileMultiple" multiple (change)="handleFileInput($event, album.albumDetailsSeq)">
                            <label for="formFileMultiple" class="form-label">Images</label>
                        </div>
                        <span class="text-danger file-size">
                            <b>Image Requirements:</b>
                            <b class="ml-3">File Size:</b> Below 1 MB,
                            <b class="ml-3">Accepted Formats:</b> JPG, JPEG, PNG
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" style="background-color: rgba(54,54,54,0.47)" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" [style.display]="isModalVisible ? 'block' : 'none'">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Album details</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal();">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="addAlbum">
                        <div class="row mb-3">
                            <div class="col-lg-12">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="albumName"
                                           placeholder="Enter Album Name">
                                    <label class="fw-bolder text-gray">Album Name</label>
                                </div>
                            </div>
                            <span class="text-danger" *ngIf="albumFormErrors.albumName">{{albumFormErrors.albumName}}</span>
                        </div>
                        <!--<div class="row mt-2">
                            <div class="col-lg-12">
                                <div class="form-floating">
                                <input class="form-control" type="file" id="formFileMultiple" multiple (change)="handleFileInput($event)">
                                    <label for="formFileMultiple" class="form-label">Images</label>
                                </div>
                            </div>
                        </div>-->
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" (click)="onSubmit();"  class="btn btn-primary">{{this.updateButtonShow == false ? 'Add Album Name' : 'Update Album Name'}}</button>
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>