import {Component, OnInit} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
@Component({
    selector: 'app-function',
    templateUrl: './function.component.html',
    styleUrls: ['./function.component.scss']
})
export class FunctionComponent implements OnInit{

    selectedWebsiteName: string;


    ngOnInit() {
        this.selectedWebsiteName = localStorage.getItem('selectedWebsiteName');
    }
}
