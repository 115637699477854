import {Component, OnInit} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss']
})
export class StudentComponent implements OnInit{

  selectedWebsiteName: string;


  ngOnInit() {
    this.selectedWebsiteName = localStorage.getItem('selectedWebsiteName');
  }
}