import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';

import {DatePipe, registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserComponent} from '@modules/main/header/user/user.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {MainMenuComponent} from '@pages/main-menu/main-menu.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import {MenuItemComponent} from '@components/menu-item/menu-item.component';
import {ControlSidebarComponent} from '@modules/main/control-sidebar/control-sidebar.component';
import {StoreModule} from '@ngrx/store';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import {ProfabricComponentsModule} from '@profabric/angular-components';
import {defineCustomElements} from '@profabric/web-components/loader';
import {SidebarSearchComponent} from '@components/sidebar-search/sidebar-search.component';
import {GalleryComponent} from "@pages/gallery/gallery.component";
import {BlogsComponent} from "@pages/blogs/blogs.component";
import {SwiperModule} from "swiper/angular";
import {NewsFeedComponent} from "@pages/news-feed/news-feed.component";
import {HomeComponent} from "@modules/home/home.component";
import {PressReleaseComponent} from "@pages/press-release/press-release.component";
import {ClientsComponent} from "@pages/clients/clients.component";
import {Ng2SearchPipeModule} from "ng2-search-filter";
import {NgxSpinnerModule} from 'ngx-spinner';
import {LazyLoadImageModule} from "ng-lazyload-image";
import {QuillModule} from "ngx-quill";
import {StudentComponent} from "@pages/students/student.component";
import {BalanceSheetComponent} from "@pages/balance-sheet/balance-sheet.component";
import {FunctionComponent} from "@pages/function/function.component";
import {LibraryComponent} from "@pages/library/library.component";

defineCustomElements();
registerLocaleData(localeEn, 'en-EN');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        ControlSidebarComponent,
        SidebarSearchComponent,
        GalleryComponent,
        BlogsComponent,
        NewsFeedComponent,
        HomeComponent,
        PressReleaseComponent,
        ClientsComponent,
        StudentComponent,
        BalanceSheetComponent,
        FunctionComponent,
        LibraryComponent
    ],
    imports: [
        BrowserModule,
        StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        ProfabricComponentsModule,
        SwiperModule,
        Ng2SearchPipeModule,
        FormsModule,
        NgxSpinnerModule,
        LazyLoadImageModule,
        QuillModule.forRoot()
    ],
    providers: [DatePipe],
    bootstrap: [AppComponent]
})
export class AppModule {
}
