<section class="content">
    <div class="container-fluid">
        <div class="row mt-2">
            <div class="col-lg-6 col-md-6 col-12 mt-md-2 mb-3">
                <h5 class="m-0 ml-3 mt-md-2 title-album">Press Release <span class="h4" *ngIf="isAdmin == 'ALL'">
                    <img src="assets/img/icons/plus.png" class="img-fluid" type="button" (click)="openModal();"></span>
                </h5>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <div class="form-floating float-lg-right">
                    <input type="search" class="form-control" placeholder="search" [(ngModel)]="searchText">
                    <label class="text-gray">Search</label>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="card direct-chat direct-chat-primary card-light-blue"
                 *ngFor="let press of this.pressReleaseList | filter: searchText;let i = index">
                <div class="card-header ui-sortable-handle border-0" type="button" style="cursor: move;"
                     (click)="toggleCard(i)">
                    <h3 class="card-title mt-2">{{press.title}}</h3>
                    <div class="btn-group ml-2">
                        <button type="button" class="btn btn-md" *ngIf="isAdmin == 'ALL' ">
                            <img src="assets/img/icons/pencil.png" class="img-fluid" title="Click to edit news feed"
                                 (click)="openEditModal(press.pressId, press.title, press.subject, press.pressReleaseDate, i, press.status)">
                        </button>
                        <button type="button" class="btn btn-md text-gradient-blue" *ngIf="isAdmin == 'ALL'">
                            <img src="assets/img/icons/bin.png" class="img-fluid" title="Click to delete news feed"
                                 (click)="deletePressDetails(press.pressId, i)">
                        </button>
                    </div>
                    <div class="card-tools">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                   [checked]="press.status === 'Showing'"
                                   (change)="openStatus(press.pressId, press.status, $event,i)">
                        </div>
                        <!--<button type="button" class="btn text-gradient-blue">
                            <i class="fas"
                               [ngClass]="{'fa-minus': !isCardCollapsed[i], 'fa-plus': isCardCollapsed[i]}"></i>
                        </button>-->
                    </div>
                </div>
                <div class="card-body" [style.display]="isCardCollapsed[i] ? 'none' : 'block'">
                    <div class="direct-chat-messages">
                        <div class="container-fluid col-lg-11">
                            <div class="row mt-3">
                                <div class="col-lg-8 col-12">
                                    <h6 class="mb-3"><b>Date :</b> <span
                                            class="ml-3">{{formatDate(press.pressReleaseDate)}}</span></h6>
                                    <h6 class="mb-3"><b>Subject:</b></h6>
                                    <span class="ml-3 text-capitalize mt-3" [innerHTML]="press.subject"></span>
                                </div>
                                <div class="col-lg-1 col-12"></div>
                                <div class="col-lg-3 col-12 text-end">
                                    <h6 class="mb-3">Attachment : <span><img src="assets/img/icons/file.png"
                                                                             class="img-fluid mb-1 ml-2" type="button"
                                                                             (click)="openOpenFile(press.pressId, i)"
                                                                             title="Click to open your attachment file"></span>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--Modal-->
        <div class="modal" style="background-color: rgba(54,54,54,0.47)" id="exampleModal" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalLabel" aria-hidden="true" [style.display]="isModalVisible ? 'block' : 'none'">
            <div class="modal-dialog modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Press Release details</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                (click)="closeModal();">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="pressRelease">
                            <div class="row mb-3">
                                <div class="col-lg-12">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="title"
                                               placeholder="Enter Press Release Title">
                                        <label class="fw-bolder text-gray">Press Release Title</label>
                                    </div>
                                </div>
                                <span class="text-danger"
                                      *ngIf="pressErrors.title">{{pressErrors.title}}</span>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-12">
                                    <!--<div class="form-floating">
                                        <textarea class="form-control" placeholder="Description" id="floatingTextarea"
                                                  formControlName="subject"></textarea>
                                        <label for="floatingTextarea" class="fw-bolder text-gray">Subject</label>
                                    </div>-->
                                    <div class="form-control quill-height">
                                        <label>Description <span
                                                class="text-danger">*</span></label>
                                        <div class="" style="min-height: 150px; overflow: auto;">
                                            <quill-editor class="quill-editor" formControlName="subject"
                                                          (onContentChanged)="onContentChanged($event)"
                                                          [modules]="editorModules">
                                            </quill-editor>
                                        </div>
                                    </div>
                                </div>
                                <span class="text-danger"
                                      *ngIf="pressErrors.subject">{{pressErrors.subject}}</span>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-12">
                                    <div class="form-floating">
                                        <input type="date" class="form-control" formControlName="pressReleaseDate"
                                               id="Datetime">
                                        <label class="fw-bolder text-gray">Date</label>
                                    </div>
                                </div>
                                <span class="text-danger"
                                      *ngIf="pressErrors.pressReleaseDate">{{pressErrors.pressReleaseDate}}</span>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-12">
                                    <div class="form-floating">
                                        <input class="form-control" type="file" (change)="onFileSelected($event)">
                                        <label class="form-label">Press Release PDF </label>
                                    </div>
                                    <span class="text-danger file-size">
                            <b>Image Requirements:</b>
                            <b class="ml-3">File Size:</b> Below 3 MB,
                            <b class="ml-3">Accepted Formats:</b> PDF
                        </span>

                                    <div class="form-floating mt-2" *ngIf="onlyEditShow == true">
                                        <input type="text" class="form-control"
                                               [value]="pressRelease.get('pdfName').value" readonly>
                                        <label class="fw-bolder text-gray">Press Release PDF Name</label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" (click)="onSubmit();"
                                class="btn btn-primary">{{this.updateButtonShow == false ? 'Add Press Release Details' : 'Update Press Release Details'}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>