import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import {GalleryComponent} from "@pages/gallery/gallery.component";
import {BlogsComponent} from "@pages/blogs/blogs.component";
import {NewsFeedComponent} from "@pages/news-feed/news-feed.component";
import {HomeComponent} from "@modules/home/home.component";
import {PressReleaseComponent} from "@pages/press-release/press-release.component";
import {ClientsComponent} from "@pages/clients/clients.component";
import {StudentComponent} from "@pages/students/student.component";
import {BalanceSheetComponent} from "@pages/balance-sheet/balance-sheet.component";
import {FunctionComponent} from "@pages/function/function.component";
import {LibraryComponent} from "@pages/library/library.component";

const routes: Routes = [
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'blank',
        component: BlankComponent
      },
      {
        path: 'sub-menu-1',
        component: SubMenuComponent
      },
      {
        path: 'sub-menu-2',
        component: BlankComponent
      },
      {
        path: 'home',
        component: DashboardComponent
      },
      {
        path: 'gallery',
        component: GalleryComponent
      },
      {
        path: 'blogs',
        component: BlogsComponent
      },
      {
        path: 'news-feed',
        component: NewsFeedComponent
      },
      {
        path: 'press-release',
        component: PressReleaseComponent
      },
      {
        path: 'company-partners',
        component: ClientsComponent
      },
      {
        path: 'students',
        component: StudentComponent
      },
      {
        path: 'balance-sheet',
        component: BalanceSheetComponent
      },
      {
        path: 'function',
        component: FunctionComponent
      },
      {
        path: 'library',
        component: LibraryComponent
      }
    ]
  },
  {
    path: '',
    component: LoginComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'recover-password',
    component: RecoverPasswordComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
