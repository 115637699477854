<section class="content">
    <div class="container-fluid">
        <div class="row mt-2 mb-2" *ngIf="this.selectedWebsiteName == 'nsbedcollegetheni.org'">
            <div class="col-12">
                <h6 class="mt-3 text-primary text-bold">Welcome to NADAR SARASWATHI COLLEGE OF EDUCATION</h6>
            </div>
        </div>
        <div class="row">
            <div class="row mt-5">
                <div class="d-flex justify-content-center">
                    <img src="assets/img/icons/upload.png" class="img-fluid"><span class="mt-3">Click here to upload</span>
                </div>
            </div>
        </div>
    </div>
</section>