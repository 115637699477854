import {Component, Renderer2} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {Subject, takeUntil} from 'rxjs';
import {NewsFeedDetails} from "@/modals/news-feed/NewsFeedDetails";
import {DatePipe} from "@angular/common";
import Swal from "sweetalert2";

@Component({
    selector: 'app-news-feed',
    templateUrl: './news-feed.component.html',
    styleUrls: ['./news-feed.component.scss'],
})

export class NewsFeedComponent {
    destroy$: Subject<boolean> = new Subject<boolean>();
    time: string[] = [];
    currentValue: string[] = [];
    isModalVisible = false;
    updateButtonShow = false;
    fileList: File[] = [];
    newsFeedDetails: NewsFeedDetails | undefined;
    allNewsFeedList: NewsFeedDetails[] = [];
    tempWebsiteName: string;
    isCardCollapsed: boolean[] = [];
    imageUrl: string;
    selectNewsFeedPosition: number;
    onlyEditShow = false;
    isButtonGroupClicked = false;
    searchText;
    newsFeedStatusList: string[] = [];
    newsId: number;
    imageShowStatus: string;
    isAdmin: string;
    instructions: string;
    selectedWebsiteName: string;
    editorModules = {
        toolbar: [
            // Custom header sizes
            [{ 'header': ['1', '2', '3', '4', '5', '6', false] }, { 'font': [] }],

            // Lists: ordered, unordered
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],

            // Inline formatting: bold, italic, underline, strike
            ['bold', 'italic', 'underline', 'strike'],

            // Text alignment
            [{ 'align': [] }],

            // Colors and background
            [{ 'color': [] }, { 'background': [] }],

            // Paragraph and span are implicit
            ['blockquote', 'code-block'],

            // Clean formatting button
            ['clean']
        ]

    };

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private datePipe: DatePipe
    ) {
    }


    news = new UntypedFormGroup({
        title: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        description: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        city: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        venueTimeStamp: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        websiteId: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        websiteName: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        newsFeedId: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        imagePath: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        imageName: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        startDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        endDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        imageStatus: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        status: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
    });

    public newsErrors = {
        title: "", description: "", city: "", venueTimeStamp: "",
    }

    ngOnInit() {
        this.isAdmin = localStorage.getItem('accessType');
        this.selectedWebsiteName = localStorage.getItem('selectedWebsiteName');
        this.allNewsFeedDetails();
    }

    handleImageError() {
        /*this.imageUrl = "src/assets/img/dummy-image.png";*/
    }

    onSubmit() {
        this.formValidation();
    }

    formValidation() {
        this.newsErrors.title = "";
        this.newsErrors.description = "";
        this.newsErrors.city = "";
        let hasError = false;

        /*   if (this.news.get("venueTimeStamp")?.invalid) {
               this.newsErrors.venueTimeStamp = "Date and Time is required";
               hasError = true;
           }*/

        if (this.news.get("title")?.invalid) {
            this.newsErrors.title = "Title is Required";
            hasError = true;
        }
        if (this.news.get("description")?.invalid) {
            this.newsErrors.description = "Description is required";
            hasError = true
        }
        if (this.news.get("city")?.invalid) {
            this.newsErrors.city = "Venue is required";
            hasError = true
        }
        /* if (!this.news.get("venueTimeStamp")?.invalid) {
             const venueDateTimeValue: string = this.news.get("venueTimeStamp")?.value;
             const epochValue: number = new Date(venueDateTimeValue).getTime();
             this.news.patchValue({venueTimeStamp: epochValue});
         }*/
        if (!hasError) {
            /*   const dateTimeString = this.news.get('venueTimeStamp').value;
               this.news.patchValue({
                   venueTimeStamp: this.convertToLon(dateTimeString),
               });

               console.log("add", this.news.value);
               this.currentValue.push(this.news.value);
               console.log("final::", this.currentValue);*/
            this.saveNewsFeedDetails();
        }
    }

    onContentChanged(event: any): void {
        this.instructions = event.html;
        console.log(this.instructions);
    }

    openModal() {
        this.isModalVisible = !this.isModalVisible;
        this.updateButtonShow = false;
    }

    closeModal() {
        this.isModalVisible = false;
        this.news.reset();
        this.imageShowStatus = "";
    }

    convertToLon(dateTimeString: string) {
        return new Date(dateTimeString).getTime();
    }

    toggleCard(index: number) {
        this.isCardCollapsed[index] = !this.isCardCollapsed[index];
    }

    getFileNameFromPath(path: string): string {
        const parts = path.split('/');
        return parts[parts.length - 1];
    }

    /*openEditModal(item: NewsFeedDetails, index: number) {
        this.updateButtonShow = true;
        this.onlyEditShow = true;
        this.selectNewsFeedPosition = index;
        this.isModalVisible = !this.isModalVisible;
        this.isCardCollapsed[index] = !this.isButtonGroupClicked;
        this.news.get('title').setValue(item.title);
        this.news.get('newsFeedId').setValue(item.newsFeedId);
        this.news.get('description').setValue(item.description);
        this.news.get('city').setValue(item.city);
        this.news.get('startDate').setValue(item.startDate);
        this.news.get('endDate').setValue(item.endDate);
        this.news.get('status').setValue(item.status);
        this.news.get('imageStatus').setValue(item.imageStatus);
        this.news.get('imagePath').setValue(item.imagePath);
        this.newsId = item.newsFeedId;
        this.imageShowStatus = item.imageStatus;
        console.log(item.imageStatus)

        /!*const formattedDate = this.datePipe.transform(date, 'yyyy-MM-ddTHH:mm');
        console.log("formattedDate::", formattedDate);
        this.news.controls['venueTimeStamp'].setValue(formattedDate);
        console.log("dd::", this.news.get('venueTimeStamp').value);*!/
        const imagePath = this.allNewsFeedList[index].imagePath;
        this.news.controls['imagePath'].setValue(imagePath);

        // Extract and set the filename separately
        const fileName = this.getFileNameFromPath(imagePath);
        this.news.controls['imageName'].setValue(fileName);
    }*/


    openEditModal(item: NewsFeedDetails, index: number) {
        this.updateButtonShow = true;
        this.onlyEditShow = true;
        this.selectNewsFeedPosition = index;

        // Toggle modal and button group collapse in a single statement
        this.isModalVisible = !this.isModalVisible;
        this.isCardCollapsed[index] = !this.isButtonGroupClicked;

        // Patch form values efficiently using destructuring
        this.news.patchValue({
            title: item.title,
            newsFeedId: item.newsFeedId,
            description: item.description,
            city: item.city,
            startDate: item.startDate,
            endDate: item.endDate,
            status: item.status,
            imageStatus: item.imageStatus,
            imagePath: item.imagePath,
        });

        this.newsId = item.newsFeedId;
        this.imageShowStatus = item.imageStatus;
        console.log(item.imageStatus);

        // Extract filename from imagePath if needed (assuming getFileNameFromPath is defined)
        const fileName = this.getFileNameFromPath(item.imagePath);
        if (fileName) {
            this.news.get('imageName').setValue(fileName);
        }
    }


    formatDate(epochTimestamp: number): string {
        return this.datePipe.transform(epochTimestamp, 'dd-MM-yyyy, HH:mm'); // Adjust the format as needed
    }

    allNewsFeedDetails() {
        this.spinner.show();
        this.tempWebsiteName = localStorage.getItem('selectedWebsiteName');
        this.appService
            .getAllNewsFeedDetails(this.tempWebsiteName)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                    this.allNewsFeedList = data;

                    if (this.allNewsFeedList.length == 0) {
                        this.toastr.info("No news feed found")
                    }
                    this.spinner.hide();
                    console.log("NewsFeed List::", this.allNewsFeedList)
                },
                (error: any) => {
                    console.log("error:", error);
                    this.spinner.hide();
                    if (error && error.error && error.error.msg) {
                        this.spinner.hide();
                        this.toastr.error(error.error.msg);
                    } else {
                        this.spinner.hide();
                        this.toastr.error("Something went wrong, please try again");
                    }
                });
    }

    onFileSelected(event: any) {
        this.fileList = []; // Clear existing files
        const allowedMimeTypes = ['image/jpg', 'image/png', 'image/jpeg'];

        for (let i = 0; i < event.target.files.length; i++) {
            const selectedFile = event.target.files[i];
            console.log(selectedFile.type)
            const fileExtension = selectedFile.name.toLowerCase().split('.').pop();
            if (!allowedMimeTypes.includes(selectedFile.type)) {
                this.toastr.error('Only JPG and PNG files are allowed!');
                continue;
            }
            if (selectedFile.size > 3 * 1024 * 1024) {
                this.toastr.error('File size is large. Please upload below 3MB!');
                continue;
            }

            this.fileList.push(selectedFile);
            console.log('File added to list:', selectedFile); // Optional logging
        }
    }

    onFileSelected1(event: any) {
        this.fileList = [];

        for (const selectedFile of event.target.files) {
            this.onlyEditShow = false;
            console.log("Select file size:", selectedFile.size);

            const fileSizeInMb = Math.round((selectedFile.size / (1024 * 1024)) * 100) / 100;
            console.log("Select file converted size:", fileSizeInMb);

            if (fileSizeInMb <= 3) {
                this.fileList.push(selectedFile);
                console.log("File list:", this.fileList);
            } else {
                this.toastr.error("File size is large. Please upload below 3MB");
            }
        }
    }

    saveNewsFeedDetails() {
        this.news.controls['websiteId'].setValue(Number(localStorage.getItem('selectedWebsiteId')));
        this.news.controls['websiteName'].setValue(localStorage.getItem('selectedWebsiteName'));
        if (!this.updateButtonShow) {
            this.news.controls['imagePath'].setValue("");
        }
        this.news.controls['status'].setValue("Showing");
        this.spinner.show();
        console.log(this.news.value);
        if (this.imageShowStatus == undefined) {
            this.news.controls['imageStatus'].setValue("");
            this.imageShowStatus = "NOT SHOWING";
        }
        const request = this.updateButtonShow
            ? this.appService.updateNewsFeedDetails(this.news.value)
            : this.appService.addNewsFeedDetails(this.news.value);
        request.pipe(takeUntil(this.destroy$)).subscribe(
            (data: any) => {
                const formData = new FormData();
                for (const image of this.fileList) {
                    formData.append('file', image, image.name);
                }
                this.spinner.hide();
                this.newsFeedDetails = data;
                console.log(this.updateButtonShow ? "Update NewsFeed::" : "NewsFeed::", this.newsFeedDetails);

                if (this.fileList.length > 0) {
                    this.saveNewsFeedImageDetails(formData, this.newsFeedDetails.newsFeedId);
                }
                this.appService.updateNewsFeedImageStatus(this.newsFeedDetails.newsFeedId, this.imageShowStatus)
                    .pipe(takeUntil(this.destroy$)).subscribe(
                    (data: any) => {
                        console.log(data);
                        this.spinner.hide();
                        this.toastr.success('Image status updated')
                    }, (error: any) => {
                        console.log("error:", error);
                        this.spinner.hide();
                        if (error && error.error && error.error.msg) {
                            this.spinner.hide();
                            this.toastr.error(error.error.msg);
                        } else {
                            this.spinner.hide();
                            this.toastr.error("Something went wrong, please try again");
                        }
                    });
                const successMessage = this.updateButtonShow ? "News Feed Updated successfully" : "News Feed added successfully";
                this.toastr.success(successMessage);

                if (this.updateButtonShow) {
                    const updatedNewsFeed = this.allNewsFeedList[this.selectNewsFeedPosition];
                    updatedNewsFeed.title = data.title;
                    updatedNewsFeed.description = data.description;
                    updatedNewsFeed.city = data.city;
                    updatedNewsFeed.venue = data.venue;
                    updatedNewsFeed.startDate = data.startDate;
                    updatedNewsFeed.endDate = data.endDate;
                    updatedNewsFeed.venueTimeStamp = data.venueTimeStamp;
                    this.selectNewsFeedPosition = null;
                } else {
                    this.allNewsFeedList.push(data);
                }

                this.news.reset();
                this.closeModal();
            },
            (error: any) => {
                console.log("error:", error);
                this.spinner.hide();
                const errorMsg = error?.error?.msg || "Something went wrong, please try again";
                this.toastr.error(errorMsg);
            }
        );
    }


    saveNewsFeedImageDetails(formData: FormData, newsFeedId: number) {
        this.spinner.show();
        this.appService
            .addNewsFeedImage(formData, newsFeedId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                    console.log("Image::", data)
                    location.reload();
                    this.spinner.hide();
                    this.closeModal();
                    this.fileList = [];
                },
                (error: any) => {
                    console.log("error:", error);
                    this.spinner.hide();
                    if (error && error.error && error.error.msg) {
                        this.spinner.hide();
                        this.toastr.error(error.error.msg);
                    } else {
                        this.spinner.hide();
                        this.toastr.error("Something went wrong, please try again");
                    }
                })
    }

    async deleteBlogDetails(newsFeedId: number, newsFeedPosition: number) {
        this.isCardCollapsed[newsFeedPosition] = !this.isButtonGroupClicked;

        const {value: confirmed} = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        });

        if (confirmed) {
            try {
                await this.spinner.show();

                const data = await this.appService.deleteNewsFeed(newsFeedId).pipe(takeUntil(this.destroy$)).toPromise();

                console.log("Deleted News Feed::", data);
                location.reload();
                this.spinner.hide();
                this.toastr.success("News Feed deleted successfully");
                this.allNewsFeedList.splice(newsFeedPosition, 1);

                await Swal.fire({
                    title: "Deleted",
                    text: "Your news feed has been deleted.",
                    icon: "success"
                });
            } catch (error) {
                this.spinner.hide();
                console.log("error:", error);
                this.handleDeleteError(error);
            } finally {
                await this.spinner.hide();
            }
        }
    }

    private handleDeleteError(error: any) {
        if (error && error.error && error.error.msg) {
            this.spinner.hide();
            this.toastr.error(error.error.msg);
        } else {
            this.spinner.hide();
            this.toastr.error("Something went wrong, please try again");
        }
    }


    openStatus(newsFeedId: number, showStatus: string, event, index) {
        this.isCardCollapsed[index] = !this.isCardCollapsed[index];
        const isChecked = event.target.checked;
        var confirmButtonText: any;
        if (isChecked) {
            showStatus = 'Showing';
            confirmButtonText = 'Show'
        } else {
            showStatus = 'Not Showing';
            confirmButtonText = 'Hide'
        }

        console.log("checked::", isChecked);

        Swal.fire({
            title: "Are you sure?",
            text: "Are you want to show or hide your news feed",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: confirmButtonText
        }).then((result) => {
            if (result.isConfirmed) {
                // The user clicked on "Okay", update the news feed status
                this.appService
                    .updateNewsFeedStatus(newsFeedId, showStatus)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(data => {
                            console.log("Update News Feed Status::", data);
                            this.newsFeedDetails = data;
                            this.toastr.success("News Feed Status Updated successfully");
                            /* const updatedNewsFeed = this.allNewsFeedList[this.selectNewsFeedPosition];
                             updatedNewsFeed.status = data.status;*/
                            Swal.fire({
                                title: "Update",
                                text: "Your news feed status has been updated.",
                                icon: "success"
                            });
                        },
                        (error: any) => {
                            console.log("error:", error);
                            this.spinner.hide();
                            if (error && error.error && error.error.msg) {
                                this.toastr.error(error.error.msg);
                            }
                        });
            } else {
                event.target.checked = !isChecked;
                console.log("Switch state after denied:", event.target.checked);
                this.toastr.info("Action canceled");
            }
        }, (error: any) => {
            console.log("error:", error);
            this.spinner.hide();
            if (error && error.error && error.error.msg) {
                this.toastr.error(error.error.msg);
            } else {
                this.toastr.error("Something went wrong, please try again");
            }
        });
    }

    updateImageShowStatus(event: any) {
        const isCheck = event.target.checked;
        if (isCheck) {
            this.imageShowStatus = "SHOWING"
            this.news.controls['imageStatus'].setValue("SHOWING");
        } else {
            this.imageShowStatus = "NOT SHOWING"
        }
    }


    /* deleteBlogDetails(newsFeedId: number, newsFeedPosition: number) {
         this.isCardCollapsed[newsFeedPosition] = !this.isButtonGroupClicked;
         Swal.fire({
             title: "Are you sure?",
             text: "You won't be able to revert this!",
             icon: "warning",
             showCancelButton: true,
             confirmButtonColor: "#3085d6",
             cancelButtonColor: "#d33",
             confirmButtonText: "Yes, delete it!"
         }).then((result) => {
             if (result.isConfirmed) {
                 this.appService
                     .deleteNewsFeed(newsFeedId)
                     .pipe(takeUntil(this.destroy$))
                     .subscribe(data => {
                         console.log("deleted News Feed::", data);
                         this.toastr.success("News Feed deleted successfully");
                         this.allNewsFeedList.splice(newsFeedPosition, 1);
                             Swal.fire({
                                 title: "Deleted",
                                 text: "Your news feed has been deleted.",
                                 icon: "success"
                             });
                         },
                         (error: any) => {
                             console.log("error:", error);
                             this.spinner.hide();
                             if (error && error.error && error.error.msg) {
                                 this.toastr.error(error.error.msg);
                             }
                         });
             }
         }, (error: any) => {
             console.log("error:", error);
             this.spinner.hide();
             if (error && error.error && error.error.msg) {
                 this.toastr.error(error.error.msg);
             } else {
                 this.toastr.error("Something went wrong, please try again");
             }
         });
     }*/


    /*saveNewsFeedDetails() {
       if (this.updateButtonShow == false) {
           this.news.controls['websiteId'].setValue(Number(localStorage.getItem('selectedWebsiteId')));
           this.news.controls['websiteName'].setValue(localStorage.getItem('selectedWebsiteName'));
           this.spinner.show();
           this.appService
               .addNewsFeedDetails(this.news.value)
               .pipe(takeUntil(this.destroy$))
               .subscribe(data => {
                       const formData = new FormData();
                       for (let image of this.fileList) {
                           formData.append('file', image, image.name);
                       }
                       this.newsFeedDetails = data;
                       console.log("NewsFeed::", this.newsFeedDetails);
                       if (this.fileList.length > 0) {
                           this.saveNewsFeedImageDetails(formData, this.newsFeedDetails.newsFeedId);
                       }
                       this.spinner.hide();
                       this.toastr.success("News Feed added successfully");
                       this.allNewsFeedList.push(data);
                       this.news.reset();
                       this.closeModal();
                   },
                   (error: any) => {
                       console.log("error:", error);

                       if (error && error.error && error.error.msg) {
                           this.toastr.error(error.error.msg);
                       } else {
                           this.toastr.error("Something went wrong, please try again");
                       }
                   });
       }
       if (this.updateButtonShow == true) {
            this.news.controls['websiteId'].setValue(Number(localStorage.getItem('selectedWebsiteId')));
            this.news.controls['websiteName'].setValue(localStorage.getItem('selectedWebsiteName'));
           this.spinner.show();
            this.appService
                .updateNewsFeedDetails(this.news.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                        const formData = new FormData();
                        for (let image of this.fileList) {
                            formData.append('file', image, image.name);
                        }
                        this.newsFeedDetails = data;
                        console.log("Update NewsFeed::", this.newsFeedDetails);
                        if (this.fileList.length > 0) {
                            this.saveNewsFeedImageDetails(formData, this.newsFeedDetails.newsFeedId);
                        }
                        this.spinner.hide();
                        this.toastr.success("News Feed Updated successfully");
                        this.allNewsFeedList[this.selectNewsFeedPosition].title = data.title;
                        this.allNewsFeedList[this.selectNewsFeedPosition].description = data.description;
                        this.allNewsFeedList[this.selectNewsFeedPosition].city = data.city;
                        this.allNewsFeedList[this.selectNewsFeedPosition].venue= data.venue;
                        this.allNewsFeedList[this.selectNewsFeedPosition].venueTimeStamp= data.venueTimeStamp;
                        this.selectNewsFeedPosition = null;
                        this.closeModal();
                    },
                    (error: any) => {
                        console.log("error:", error);

                        if (error && error.error && error.error.msg) {
                            this.toastr.error(error.error.msg);
                        } else {
                            this.toastr.error("Something went wrong, please try again");
                        }
                    });
        }
    }*/

}