import {Component, OnInit} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
@Component({
    selector: 'app-balance-sheet',
    templateUrl: './balance-sheet.component.html',
    styleUrls: ['./balance-sheet.component.scss']
})
export class BalanceSheetComponent implements OnInit{
    selectedWebsiteName: string


    ngOnInit() {
        this.selectedWebsiteName = localStorage.getItem('selectedWebsiteName');
    }
}
